import React, { useEffect } from 'react';
import Home from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import About from './pages/About';
import axios from 'axios';
import { ConfigProvider, Result } from 'antd';
import Loading from './components/Loading';
import Survey from './pages/Survey';
import { BsTiktok, BsTwitterX, BsYoutube } from 'react-icons/bs';
import { FaFacebookF, FaInstagram, FaTelegramPlane } from 'react-icons/fa';
import Skills from './pages/skills';
import Scholarships from './pages/scholarships';
function App() {
  const [open, setOpen] = React.useState(false)
  const [loading, setLoading] = React.useState(true)
  const [value, setValue] = React.useState({
    survey: '',
    search: '',
  })
  const change = (name, value) => {
    setValue((state) => {
      return { ...state, [name]: value }
    })
  }

  useEffect(() => {
    async function getData() {
      await axios.get(`/setting/api/v1/settings/find/?key=survey`)
        .then(res => {
          change("survey", res.data.value)
        })
      await axios.get(`/setting/api/v1/settings/find/?key=search`)
        .then(res => {
          change("search", res.data.value)
          if (res.status === 200) setTimeout(() => setLoading(false), 1000)
        })
    }
    getData()

  }, [])
  const NotFound = () => {
    React.useEffect(() => {
      const effect = () => {
        const seeing = document.getElementById('seeing-link');
        const ethics = document.getElementById('ethics-link');
        const missions = document.getElementById('missions-link');
        const skill = document.getElementById('skill-link');
        const scholarships = document.getElementById('scholarships-link');
        skill.classList.add('d-none')
        scholarships.classList.add('d-none')
        seeing.classList.add('d-none')
        ethics.classList.add('d-none')
        missions.classList.add('d-none')
      }
      effect()
    }, [])
    return (
      <Result status="404" style={{ marginTop: 160, marginBottom: 160 }} title="404" subTitle="عذرا ، الصفحة التي قمت بزيارتها غير موجودة." />
    )
  }
  return (
    <ConfigProvider
      direction='rtl'
      theme={{
        token: {
          fontFamily: 'RB-r'
        }
      }}
    >
      <Router>
        <header id="header" className="fixed-top">
          <div className="container d-flex align-items-center justify-content-between">
            <a href="/" className="logo"><img src={require('./style/img/home/logo2.png')} alt="" className="img-fluid" /></a>
            <nav id="navbar" className="navbar">
              <ul>
                <li><a className="nav-link scrollto" href="/" style={{ color: 'var(--second-color)' }}>الرئيسية</a></li>
                <li><a className="nav-link scrollto" href="/about">من نحن</a></li>
                <li><a className="nav-link scrollto" id="skill-link" href="#skill">مهارة</a></li>
                <li><a className="nav-link scrollto" id="scholarships-link" href="#scholarships">منح الدراسية</a></li>

                <li><a className="nav-link scrollto" id="seeing-link" href="#seeing">الرؤية</a></li>
                <li><a className="nav-link scrollto" id="missions-link" href="#missions">المهمات</a></li>
                <li><a className="nav-link scrollto" id="ethics-link" href="#ethics">تواصل معنا</a></li>
                {value.survey === "true" && value.search === "true" ?
                  <li><a className="nav-link scrollto" href="/survey">أستمارة البحوث الجامعية</a></li>
                  :
                  null
                }
              </ul>
              <i onClick={() => setOpen(!open)} className="bi bi-list mobile-nav-toggle"></i>
            </nav>
          </div>
          <div id="sidebar" className={open ? 'sidebar active' : 'sidebar'}>
            <a href="/" className="d-flex align-items-center justify-content-center m-3"><img width={75} src={require('./style/img/home/logo2.png')} alt="" className="img-fluid" /></a>
            <ul className='mt-5'>
              <li><a className="nav-link scrollto" href="/">الرئيسية</a></li>
              <li><a className="nav-link scrollto" href="/about">من نحن</a></li>
              {value.survey === "true" && value.search === "true" ?
                <li><a className="nav-link scrollto" href="/survey">أستمارة البحوث الجامعية</a></li>
                :
                null
              }
            </ul>
          </div>
        </header>
        {loading && <Loading />}
        <div onClick={() => setOpen(false)}>
          <Routes>
            <Route
              exact={true}
              path='/'
              element={<Home survey={value.survey} search={value.search}  />}
            />
            {value.survey === "true" && value.search === "true" ?
              <Route
                exact={true}
                path='/survey'
                element={<Survey />}
              />
              :
              null
            }
            <Route
              exact={true}
              path='/about'
              element={<About />}
            />
            <Route
              exact={true}
              path='/skill'
              element={<Skills />}
            />
            <Route
              exact={true}
              path='/scholarships'
              element={<Scholarships />}
            />
            <Route
              exact={true}
              path='*'
              element={<NotFound/>}
            />
          </Routes>
        </div>
        <footer class="site-footer">
          <div class="container">
            <div class="row justify-content-between  align-items-end ">

              <div class="col-xs-12 col-md-4">
                <p>للاستعلام الاتصال على الرقم التالي  07759287092</p>
              </div>
            </div>
            <hr />
          </div>
          <div class="container">
            <div class="row">
              <div class="col-md-8 col-sm-6 col-xs-12">
                <p class="copyright-text">
                  &copy; جميع الحقوق محفوظة لدى المديرية العامة للعلاقات والخدمات الاجتماعية
                </p>
              </div>

              <div class="col-md-4 col-sm-6 col-xs-12">
                <ul class="social-icons">
                  <li><a class="facebook" href="https://www.facebook.com/almuhandise?mibextid=ZbWKwL"><FaFacebookF /></a></li>
                  <li><a class="instagram" href="https://www.instagram.com/al_muhandise/?igsh=MXN1dHR4MW92MW42OA%3D%3D"><FaInstagram /></a></li>
                  <li><a class="telegram" href="https://t.me/abnaaalmuhandis"><FaTelegramPlane /></a></li>
                  <li><a class="tiktok" href="https://www.tiktok.com/@al_muhandise?_t=8mEv8PMnf4j&_r=1"><BsTiktok /></a></li>
                  <li><a class="youtube" href="https://www.youtube.com/@abnaa_gen"><BsYoutube /></a></li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
        <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
      </Router>
    </ConfigProvider>
  );
}

export default App;
