import React, { useEffect, useState } from 'react';
import { Button, Form, Input, InputNumber, message, Result, Select, Space, Steps, theme } from 'antd';
import { FileDoneOutlined, InfoCircleOutlined, InfoOutlined, ProfileOutlined, ReconciliationOutlined, UserOutlined, PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { AddPop, ErrorPop, OKPop } from '../components/PopForm';
import Uploads from '../components/upload';
import { uid } from 'uid';
export default function Survey() {
    const [form] = Form.useForm()
    const { token } = theme.useToken();
    const [current, setCurrent] = useState(0);
    const [fileList, setFileList] = useState([]);
    const [oldFiles, setOldFiles] = useState([])
    const [files, setFiles] = useState([])

    const [trips, setTrips] = useState([]);
    const [formData, setFormData] = useState({
        uid: uid(16),
        name: '',
        search_names: ['']
    });
    const [post, setPost] = useState({
        progress: 0,
        count: 0,
    })
    // search values to select forms
    const [formPop, setFormPop] = useState({
        showOne: false,
        showTwo: false,
        showError: false,
        ok: false,
        errorMessage: '',
    })

    const changePost = (name, value) => {
        setPost((state) => {
            return { ...state, [name]: value }
        })
    }
    // func to change values in pop form
    const popForm = (name, value) => {
        setFormPop((state) => {
            return { ...state, [name]: value }
        })
    }

    const fileSend = (value) => {
        setFiles((state) => {
            return [...state, value]
        })
    }
    React.useEffect(() => {
        const effect = () => {
            const seeing = document.getElementById('seeing-link');
            const ethics = document.getElementById('ethics-link');
            const missions = document.getElementById('missions-link');
            const skill = document.getElementById('skill-link');
            const scholarships = document.getElementById('scholarships-link');
            skill.classList.add('d-none')
            scholarships.classList.add('d-none')
            seeing.classList.add('d-none')
            ethics.classList.add('d-none')
            missions.classList.add('d-none')
        }
        effect()
    },[])
    const postData = async (event) => {
        event.preventDefault()

        const UploadProgress = (event) => {
            const { loaded, total } = event;
            let percent = Math.floor((loaded * 100) / total);
            console.log(`${loaded}kb of ${total}kb | ${percent}%`);
            changePost('progress', percent)
        }
        const option = {
            timeout: 5000000,
            onUploadProgress: UploadProgress
        }

        await axios.post('/contributions/api/v1/contributions/', formData, option)
            .catch(error => {
                popForm('showTwo', false);
                popForm('showError', true);
                changePost('progress', 0);
                popForm('errorMessage', "رجاءا تأكد من الاتصال");
            }).then(res => {
                console.log(res.status)
                if (res.status === 201) {
                    popForm('showTwo', false);
                    popForm('ok', true);
                    setTimeout(() => {
                        window.location.reload()
                    }, 2000);
                }
            })
    }
    const next = () => {
        setCurrent(current + 1);
    };
    const prev = () => {
        setCurrent(current - 1);
    };

    const Informations = () => {
        return (
            <div className='d-flex flex-column justify-content-start align-items-start'>
                <div className='information d-flex flex-column justify-content-start align-items-start'>
                    <div className='d-flex justify-content-start align-items-center w-100'>
                        <p className='step-title'>يرجى قراءة التوجيهات والارشادات العامة قبل ملئ الاستمارة</p>
                    </div>
                    <p className='information-text'><p>1</p> تأكد جيداً من اسمك الثلاثي وبالعربية حصراً</p>
                    <p className='information-text'><p>2</p> تأكد جيداً من كتابة رقم هاتفك بشكل صحيح</p>
                    <p className='information-text'><p>3</p> البريد الالكتروني اختيارياً</p>
                    <p className='information-text'><p>4</p>
                        تأكد من كتابة اسم جامعتك وكليتك واختصاصك بدقة كما في الامثلة : 
                        <br/>
                        جامعة بغداد - كلية هندسة الخوارزمي - طب حياتي 
                        <br />
                        جامعة بغداد - كلية الادارة والاقتصاد - الاحصاء
                        <br />
                        الجامعة المستنصرية - كلية التربية - الارشاد النفسي والتوجية التربوي 
                    </p>
                    <p className='information-text'><p>5</p> يرجى كتابة اسم الباحث في  خانة ( الباحث ) وفي حال وجود اكثر من باحث يمكنك النقر على خيار (اضافة باحث)</p>
                    <p className='information-text'><p>6</p> يمكنك ارفاق خمسة ملفات فقط</p>
                    <p className='information-text'><p>7</p> الحد المسموح لحجم الملف الواحد هو 20 ميغابايت فقط</p>
                    <p className='information-text'><p>8</p> الملفات المسموحة بالرفع هي ( pdf-docx-png-jpeg-csv-xlsx)</p>
                    <p className='information-text'><p>9</p> تأكد من نسخ الرمز التعريفي للأستمارة</p>
                </div>
                <div className='d-flex justify-content-start align-items-center w-100'>
                    <p className='step-title'>معلومات الأتصال</p>
                </div>
                <div className='information d-flex flex-column justify-content-start align-items-start'>
                    <p className='information-text'><p>1</p> للاستعلام الاتصال على الرقم التالي  07759287092</p>
                    <p className='information-text'><p>2</p> علماً أن الرقم يحتوي على واتساب و تليكرام.</p>
                </div>

            </div>
        )
    }

    const Res = () => {
        return (
            <Result
                status="success"
                title="تم ملء الاستمارة بنجاح"
                subTitle={`
                الرمز التعريفي للأستمارة
                ${formData.uid}
                `}
                extra={<Button onClick={() => navigator.clipboard.writeText(formData.uid)}>نسخ الرمز</Button>}
            />
        )
    }
    const steps = [
        {
            title: 'التوجيهات العامة للمشاركين',
        },
        {
            title: 'بيانات الأستمارة',
        },
        {
            title: 'بيانات البحث',
        },
        {
            title: 'رفع البيانات',
        },
    ];

    const items = steps.map((item) => ({
        key: item.title,
        title: item.title,
    }));
    const contentStyle = {

        color: token.colorTextTertiary,
        backgroundColor: token.colorFillAlter,
        borderRadius: token.borderRadiusLG,
        border: `1px dashed ${token.colorBorder}`,
        marginTop: 16,
        zIndex: 3
    };
    // const validateName = (rule, value, callback) => {
    //     const forbiddenWords = ['w1', 'w2', 'w3'];

    //     const containsForbiddenWord = forbiddenWords.some(word => value.includes(word));

    //     if (containsForbiddenWord) {
    //         callback('الرجاء عدم استخدام كلمات نابية.');
    //     } else {
    //         callback();
    //     }
    // };
    const onPost = (values) => {
        if (values.has_children) {
            setFormData((state) => {
                return { ...state, ...values, files }
            })
        } else {
            setFormData((state) => {
                return { ...state, ...values, files }
            })
        }
        next()
    }
    console.log(files)
    return (
        <>
            <div className="container mb-5" style={{ minHeight: "80vh", overflow: 'auto', marginTop: 100 }} >
                <div className="row">
                    <div className="col-12 d-flex flex-column justify-content-center align-items-center" style={{ zIndex: 3 }}>
                        <img src={require('../style/img/home/logo.png')} alt="" className="img-fluid" width={175} />
                        <h3 className='text-center pt-2 pb-5'>أستمارة البحوث الجامعية</h3>
                    </div>
                    <div class="col-12 h-100">
                        <Form form={form} onFinish={(values) => onPost(values)} initialValues={formData}>
                            <Steps current={current} items={items} style={{ zIndex: 3 }} />
                            {current === 0 &&
                                <div style={{ ...contentStyle }}>
                                    <Informations />
                                </div>}
                            {current === 1 &&
                                <div style={{ ...contentStyle }}>
                                    <div className='d-flex justify-content-start align-items-center w-100'>
                                        <p className='step-title'>بيانات الأستمارة</p>
                                    </div>
                                    <div class="container-fluid p-2 mt-1 mb-4 ">
                                        <div class="row justify-content-start align-items-start g-2">
                                            <div class="col-12">
                                                <label className='text-end optional'>الأسم الثلاثي :</label>
                                                <Form.Item
                                                    name='name'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                        {
                                                            message: 'الأسم لا يقل عن عشرة حروف',
                                                            min: 10,
                                                        },
                                                        {
                                                            message: 'الأسم لا يزيد عن خمسون حرفاً',
                                                            max: 50
                                                        },
                                                        {
                                                            pattern: "^[\\u0600-\\u06FF\\s]*$",
                                                            message: 'الاسم يجب أن يكون بالعربية',
                                                        },
                                                        // {
                                                        //     validator: validateName,
                                                        // },
                                                    ]}
                                                    extra='الأسم الثلاثي يكون باللغة العربيه حصراً'
                                                    hasFeedback

                                                >
                                                    <Input type='text' minLength={2} size="large" placeholder='الأسم الثلاثي' name='name' />
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">

                                                <label className='text-end optional'>رقم الهاتف :</label>
                                                <Form.Item
                                                    name='phone'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                        {
                                                            message: 'رقم الهاتف الحالي غير فعال',
                                                            min: 11,
                                                            max: 11
                                                        },
                                                        {
                                                            pattern: /^[0-9]+$/,
                                                            message: 'يجب أن يحتوي رقم الهاتف على أرقام فقط وباللغة الإنجليزية',
                                                        },
                                                    ]}
                                                    extra='رقم الهاتف لا يقل ولا يزيد عن 11 رقم وباللغة الإنجليزية'
                                                    hasFeedback
                                                >
                                                    <Input type='text' className='w-100' inputMode='numeric' size="large" maxLength={11} placeholder='رقم الهاتف' name='phone' />
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end'>البريد الألكتروني :</label>
                                                <Form.Item
                                                    name='email'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: false,
                                                        },
                                                        {
                                                            type: 'email',
                                                            message: 'اكتب بريد ألكتروني فعال'
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Input type='email' minLength={2} size="large" placeholder='البريد الألكتروني' name='email' />
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>هل انت من احد الفئات التالية :</label>
                                                <Form.Item
                                                    name='type'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Select
                                                        size="large"
                                                        className='col-12'
                                                        placeholder='أختر'
                                                    >
                                                        <option value={1}>ذوي الشهداء</option>
                                                        <option value={2}>ذوي الجرحى</option>
                                                        <option value={0}>لاشيء مما سبق</option>
                                                   </Select>
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>الجامعة :</label>
                                                <Form.Item
                                                    name='university'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Input type='text' minLength={2} size="large" placeholder='الجامعة' name='university' />
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>الكلية :</label>
                                                <Form.Item
                                                    name='college'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Input type='text' minLength={2} size="large" placeholder='الكلية' name='college' />
                                                </Form.Item>
                                            </div>
                                            <div class="col-lg-4 col-md-6 col-sm-12">
                                                <label className='text-end optional'>القسم :</label>
                                                <Form.Item
                                                    name='section'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Input type='text' minLength={2} size="large" placeholder='القسم' name='section' />
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {current === 2 &&
                                <div style={{ ...contentStyle }}>
                                    <div className='d-flex justify-content-start align-items-center w-100'>
                                        <p className='step-title'>بيانات البحث</p>
                                    </div>
                                    <div class="container-fluid p-2 mt-1 mb-4 ">
                                        <div class="row justify-content-start align-items-start g-2">
                                            <div class="col-12">
                                                <label className='text-end optional'>عنوان البحث :</label>
                                                <Form.Item
                                                    name='search_title'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                        {
                                                            message: 'الأسم لا يقل عن عشرة حروف',
                                                            min: 10,
                                                        },
                                                    ]}
                                                    hasFeedback

                                                >
                                                    <Input type='text' minLength={2} size="large" placeholder='عنوان البحث' name='search_title' />
                                                </Form.Item>
                                            </div>
                                            <div class="col-12">
                                                <label className='text-end optional'>ملخص البحث :</label>
                                                <Form.Item
                                                    name='text'
                                                    className='my-0'
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                    ]}
                                                    hasFeedback
                                                >
                                                    <Input.TextArea className='w-100' rows={10} placeholder='ملخص البحث' name='text' />
                                                </Form.Item>
                                            </div>
                                            <div className="col-12">
                                                <label className='text-end optional'>المرفقات :</label>
                                                <Form.Item
                                                    className='my-0'
                                                    name='file'
                                                    rules={[
                                                        {
                                                            required: JSON.stringify(files) === JSON.stringify([]) ? true : false,
                                                            message: 'هذا الحقل الزامي',
                                                        },
                                                        {
                                                            type:'array',
                                                            max:5,
                                                            message: 'العدد المطلوب 5 فقط',
                                                        },
                                                    ]}
                                                >
                                                    <Uploads
                                                        fileSend={fileSend}
                                                        files={files}
                                                        fileList={fileList}
                                                        setFileList={setFileList}
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div class="col-12">
                                                <label className='text-end optional'>الباحثين :</label>
                                                <Form.List name="search_names" className='my-0'>
                                                    {(fields, { add, remove }) => (
                                                        <>
                                                            {fields.map(({ key, name, ...restField }) => (
                                                                <div
                                                                    key={key}
                                                                    style={{
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        marginBottom: 8,
                                                                        width: '100%'
                                                                    }}
                                                                    align="baseline"
                                                                >
                                                                    <Form.Item
                                                                        {...restField}
                                                                        label='الباحث'
                                                                        name={[name]}
                                                                        className='my-0 w-100'
                                                                        rules={[
                                                                            {
                                                                                required: true,
                                                                                message: 'هذا الحقل الزامي',
                                                                            },
                                                                        ]}
                                                                    >
                                                                        <Input placeholder="الباحث" size='large' className='w-100' />
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        className='my-0 px-3'
                                                                    >
                                                                        <MinusCircleOutlined onClick={() => remove(name)} />
                                                                    </Form.Item>
                                                                </div>
                                                            ))}
                                                            <Form.Item>
                                                                <Button type="dashed"
                                                                    onClick={() => add()}
                                                                    style={{
                                                                        width: 150,
                                                                        margin: 5,
                                                                        float: 'left'
                                                                    }}
                                                                    block
                                                                >
                                                                    أضافة باحث
                                                                </Button>
                                                            </Form.Item>
                                                        </>
                                                    )}
                                                </Form.List>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {current === 3 && <div style={{ ...contentStyle }}><Res /></div>}
                            <div
                                style={{
                                    marginTop: 24,
                                    direction: 'ltr',
                                    display: 'flex',
                                    justifyContent: 'space-between'
                                }}
                            >
                                {current === 0 && (
                                    <Button
                                        type=""
                                        className='survey-btn'
                                        onClick={() => next()}
                                    >
                                        التالي
                                    </Button>
                                )}
                                {current === 1 && (
                                    <Button
                                        type=""
                                        className='survey-btn'
                                        // onClick={() => next()}
                                        htmlType='submit'
                                    >
                                        التالي
                                    </Button>
                                )}
                                {current === 2 && (
                                    <Button
                                        type=""
                                        className='survey-btn'
                                        // onClick={() => next()}
                                        htmlType='submit'
                                    >
                                        التالي
                                    </Button>
                                )}
                                {current === steps.length - 1 &&
                                    (
                                        <Button type="" className='survey-btn' onClick={() => popForm('showOne', true)}>
                                            رفع بيانات الأستمارة
                                        </Button>
                                    )
                                }
                                {current > 0 &&
                                    (
                                        <Button
                                            style={{
                                                margin: '0 8px',
                                            }}
                                            onClick={() => prev()}
                                        >
                                            السابق
                                        </Button>
                                    )
                                }
                            </div>
                        </Form>
                    </div>
                </div>
            </div>
            <AddPop
                showOne={formPop.showOne}
                showTwo={formPop.showTwo}
                handleshow={(e) => {
                    popForm('showOne', false)
                    postData(e)
                    popForm('showTwo', true)
                }}
                handleCloseOne={() => popForm('showOne', false)}
                handleCloseTwo={() => popForm('showTwo', false)}
                post={post.progress}
            />
            <ErrorPop
                showError={formPop.showError}
                handleCloseError={() => popForm('showError', false)}
                errorMessage={formPop.errorMessage}
            />
            <OKPop
                showError={formPop.ok}
                handleCloseError={() => popForm('ok', false)}
            />
        </>
    )
}
