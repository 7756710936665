import { Image } from 'antd'
import React from 'react'
import { BsArrowLeft, BsBook, BsTiktok, BsTwitterX, BsYoutube } from 'react-icons/bs';
import { FaFacebookF, FaInstagram, FaTelegramPlane } from 'react-icons/fa';

export default function Home({ survey, search }) {
    return (
        <>
            <section id="home" className='section-one'>
                <div className='section-one__cover'>
                    <div
                        class="container-fluid"
                    >
                        <div
                            class="row justify-content-center align-items-center"
                        >
                            <div class="col-lg-7 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
                                <div className='d-flex flex-column justify-content-center align-items-center h-100  w-75' style={{ zIndex: 2 }}>
                                    <img
                                        src={require('../style/img/home/logo.png')}
                                        width={450}
                                        className="img-fluid section-one__logo"
                                        alt=""
                                    />
                                    <p className='section-one__title'>
                                        <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                                        <span> هؤلاء ابناء ابنائي كأنهم من لحمي ودمي </span>
                                        <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                                    </p>
                                    <h6 className='mb-5' style={{ textAlign: 'justify' }}>مرحباً بكم في الموقع الرسمي لفريق أبناء المهندس والذي هو فريق طلابي متميز يجسد القيم الإنسانية والعلمية بأفضل صورها، حيث يسعى لتحقيق أهدافه من خلال تعزيز التفاعل الاجتماعي والتحصيل العلمي ، في فريق أبناء المهندس نهدف إلى تحقيق الثأر لدماء القادة، وذلك من خلال تمثيل مبادئ وقيم الشهيد شهيد الامة الباسل ابي مهدي المهندس رضوان الله عليه كما نسعى الى توفير بيئة تعليمية وتثقيفية محفزة وتنظيم فعاليات ثقافية وعلمية واجتماعية تعكس مهمتنا وقيمنا التي نؤمن بها بهدف تحقيق الريادة العلمية والثقافية في الجامعات والمعاهد العراقية</h6>
                                    {survey === "true" && search === "true" ?
                                        <div>
                                            <a href="/survey" className="section-one__btn ps-5 pe-5">أستمارة البحوث الجامعية <BsBook size={18} /></a>
                                        </div>
                                        :
                                        null
                                    }
                                </div>
                            </div>
                            <div class="col">
                                <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                                    <img
                                        className='section-one__image'
                                        src={require('../style/img/home/thum_vid.jpg')}
                                        alt="..."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <main id="royatna">
                <section id="skill" className="counts">
                    <div className="container  position-relative">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-6 order-2 order-md-1">
                                <div className="title text-center mt-3">
                                    <h3 style={{ fontSize: 45, color: '#F89C42' }}>مهارة</h3>
                                    <h4>مبادرة لتدريب وتطوير الشباب برعاية المديرية العامة للعلاقات والخدمات الاجتماعية</h4>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <a href="/skill" className="section-one__more mt-2 ">للمزيد  <BsArrowLeft size={18} /></a>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 d-flex justify-content-center align-items-center order-1 order-md-2">
                                <div className="mask">
                                    <img src={require('../style/img/home/mr33.jpg')} alt="" className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section id="scholarships" className="counts">
                    <div className="container  position-relative">
                        <div className="row justify-content-center align-items-center">
                            <div className="col-lg-6 col-md-6 d-flex justify-content-end align-items-center ">
                                <div className="mask">
                                    <img src={require('../style/img/home/mr44.jpeg')} alt="" className="img-fluid" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <div className="title text-center mt-3">
                                    <h3 style={{ fontSize: 45, color: '#F89C42' }}>منح دراسية</h3>
                                    <h4>انطلق نحو آفاق علمية جديدة</h4>
                                    <div className='d-flex justify-content-center align-items-center'>
                                        <a href="/scholarships" className="section-one__more mt-2 ">للمزيد  <BsArrowLeft size={18} /></a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
                <section id="seeing" className="counts">
                    <div className="container">
                        <div className="text-center title">
                            <h3 style={{ fontSize: 45, color: '#F89C42' }}>رؤيتنا</h3>
                            <h4>نموذج للشاب الجامعي و للفرق الطلابية الفاعلة و الهادفة التي يحلم اي طالب ان يكون جزء منها</h4>
                        </div>
                    </div>
                </section>
                <section id="target" className="testimonials">
                    <div className="container position-relative">
                        <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="testimonial-item">
                                        <img src={require('../style/img/home/logo_w.png')} className="testimonial-img" alt="" />
                                        <p className='mt-4'>
                                            <span>أبناء المهندس نحو الريادة والصدارة والتفوق بالمجال العلمي والعملي للطلبة</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                </section>

                <section id="missions" className="gallery">
                    <div className="container">

                        <div className="section-title mb-5">
                            <h3 style={{ fontSize: 45, color: '#764C9F' }}>مهماتنا</h3>
                            <h5>توفير بيئة تعليمية و تثقيفية و تنظيم فعاليات ثقافية و علمية واجتماعية تعكس مبادئنا و تعزز التطور الشخصي و الاجتماعي لبناء تجمع طلابي يحتل المراكز العلمية الأولى في الجامعات والمعاهد</h5>
                        </div>

                        <div className="row g-1 justify-content-center align-items-center">

                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr1.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr2.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            {/* 
                            <div className="col-lg-3 col-md-4 col-12">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr3.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr4.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-lg-3 col-md-4 col-12">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr5.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div> */}

                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr6.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr7.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-12">
                                <div className="gallery-item">
                                    <div className="galleery-lightbox" data-gallery="gallery-item">
                                        <Image preview={{ mask: false }} src={require('../style/img/home/mr8.jpg')} alt="" className="img-fluid" />
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </section>
                <section id="ethics" className="about image-move mb-4">
                    <div className="container ">
                        <div className="row justify-content-center  align-items-center">
                            <div className="col-12 d-flex justify-content-center  align-items-center position-relative">
                                <h3 style={{ fontSize: 45, color: '#764C9F' }}>تواصل معنا</h3>
                            </div>
                            <div className="col-12 d-flex justify-content-center  align-items-center position-relative">
                                <img
                                    src={require('../style/img/home/logo.png')}
                                    className=""
                                    style={{
                                        width: 250
                                    }}
                                    alt=""
                                />
                            </div>
                            <div className="col-12  d-flex align-items-center justify-content-center py-2">
                                <ul class="social-icons social-icons-2  m-0 mt-4">
                                    <li><a class="facebook" href="https://www.facebook.com/almuhandise?mibextid=ZbWKwL"><FaFacebookF /></a></li>
                                    <li><a class="instagram" href="https://www.instagram.com/al_muhandise/?igsh=MXN1dHR4MW92MW42OA%3D%3D"><FaInstagram /></a></li>
                                    <li><a class="telegram" href="https://t.me/abnaaalmuhandis"><FaTelegramPlane /></a></li>
                                    <li><a class="tiktok" href="https://www.tiktok.com/@al_muhandise?_t=8mEv8PMnf4j&_r=1"><BsTiktok /></a></li>
                                    <li><a class="youtube" href="https://www.youtube.com/@abnaa_gen"><BsYoutube /></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </>
    )
}
