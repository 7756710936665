import React from 'react'
import { BsArrowLeft, BsBook, BsList } from 'react-icons/bs'

export default function Scholarships() {
    React.useEffect(() => {
        const effect = () => {
            const seeing = document.getElementById('seeing-link');
            const ethics = document.getElementById('ethics-link');
            const missions = document.getElementById('missions-link');
            const skill = document.getElementById('skill-link');
            const scholarships = document.getElementById('scholarships-link');
            skill.classList.add('d-none')
            scholarships.classList.add('d-none')
            seeing.classList.add('d-none')
            ethics.classList.add('d-none')
            missions.classList.add('d-none')
        }
        effect()
    }, [])
    return (
        <div>
            <div
                class="container-fluid mt-5"
            >
                <div
                    class="row justify-content-center align-items-center g-2"
                >
                    <div class="col-12">
                        <section id="seeing" className="counts">
                            <div className="container  position-relative">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-lg-6 col-md-6 order-2 order-md-1 d-flex justify-content-center align-items-center">
                                        <div className="title text-center my-4">
                                            <h3>انطلق نحو  <span style={{ color: '#F89C42' }}>آفاق</span>  علمية جديدة</h3>
                                            <div className='d-flex justify-content-center align-items-center mt-5'>
                                                <a href="https://scholar-ships.emtenan.org" className="section-one__btn ps-5 pe-5 mt-2">للتقديم اضغط هنا</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 order-1 order-md-2 d-flex justify-content-center align-items-center ">

                                        <div className="mask">
                                            <img src={require('../style/img/home/mr44.jpeg')} alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <div
                class="container"
            >
                <div
                    class="row justify-content-center align-items-center g-2"
                >
                    <div class="col-xl-11  px-1 px-lg-4">
                        <h5 className='mb-3'>منح دراسية مميزة في أفضل الجامعات الإيرانية ذات التصنيف العالمي برعاية المديرية العامة للعلاقات والخدمات الاجتماعية </h5>
                        <h6>لماذا الدراسة في إيران؟</h6>
                        <ul className='ul-list'>
                            <li><strong>جامعات مرموقة عالميًا</strong> : انضم إلى مؤسسات أكاديمية رائدة مصنفة عالميًا، تتميز بتميزها البحثي ومناهجها المتطورة. </li>
                            <li><strong>تراث علمي وثقافي فريد</strong> : تعرّف على حضارة عريقة واكتشف كنوزًا من المعرفة والفنون.</li>
                            <li><strong>بيئة آمنة ومضيافة</strong> : عش تجربة دراسية لا تُنسى في مجتمع يرحب بالطلاب الدوليين.</li>
                            <li><strong>فرصة لتطوير الذات</strong> : اكتسب مهارات جديدة ووسّع آفاقك الثقافية والعلمية.</li>

                        </ul>
                        <h6>درجات علمية متنوعة في متناول يديك:</h6>
                        <ul className='ul-list'>
                            <li><strong>البكالوريوس</strong> : في جميع التخصصات العلمية والادبية و الفنية ( عدا التخصصات الطبية )</li>
                            <li><strong>الماجستير</strong> : في جميع التخصصات العلمية والادبية و الفنية ( عدا التخصصات الطبية )</li>
                            <li><strong>الدكتوراه </strong> : في جميع التخصصات العلمية والادبية و الفنية ( عدا التخصصات الطبية )</li>
                        </ul>
                        <h6>ما نوفره لك هو : دعم شامل لنجاحك</h6>
                        <ul className='ul-list'>
                            <li><strong>إعفاء كامل من الرسوم الدراسية.</strong></li>
                            <li><strong>سكن جامعي مريح ومجاني.</strong></li>
                            <li><strong>إرشاد أكاديمي وثقافي.</strong></li>
                            <li><strong>فرص للتفاعل مع المجتمع الإيراني.</strong></li>
                        </ul>
                        <h6>فرصة الدراسة في افضل الجامعات الايرانية حسب التصنيف العالمي:</h6>
                        <ul className='ul-list'>
                            <li><strong>جامعة شريف للتكنولوجيا</strong> : رائدة في مجالات الهندسة والتكنولوجيا. </li>
                            <li><strong>جامعة طهران</strong> : جامعة شاملة عريقة تتميز بتنوع تخصصاتها.</li>
                            <li><strong>جامعة أمير كبير للتكنولوجيا</strong> : متخصصة في الهندسة والتكنولوجيا التطبيقية.</li>
                            <li><strong>جامعة العلوم والصناعة</strong> : تقدم برامج متميزة في العلوم والهندسة.</li>
                            <li><strong>جامعة شيراز</strong> : جامعة تاريخية عريقة ذات سمعة أكاديمية مرموقة.</li>
                        </ul>
                    </div>
                    <div className="col-12 text-center my-4">
                        <p>"انضموا إلينا الآن وابدأوا رحلتكم نحو التميز والنجاح"</p>
                        <h5>للاستفسار التواصل على الرقم التالي</h5>
                        <h5>07865190467</h5>
                    </div>
                </div>

            </div>


        </div>
    )
}
