import React from 'react'
import { BsArrowLeft } from 'react-icons/bs'

export default function Skills() {
    React.useEffect(() => {
        const effect = () => {
            const seeing = document.getElementById('seeing-link');
            const ethics = document.getElementById('ethics-link');
            const missions = document.getElementById('missions-link');
            const skill = document.getElementById('skill-link');
            const scholarships = document.getElementById('scholarships-link');
            skill.classList.add('d-none')
            scholarships.classList.add('d-none')
            seeing.classList.add('d-none')
            ethics.classList.add('d-none')
            missions.classList.add('d-none')
        }
        effect()
    },[])
    return (
      <div>
            <div
                class="container-fluid mt-5"
            >
                <div
                    class="row justify-content-center align-items-center g-2"
                >
                    <div class="col-12">
                        <section id="seeing" className="counts">
                            <div className="container  position-relative">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-lg-6  order-2 order-lg-1 d-flex justify-content-center align-items-center ">
                                        <div className="title text-center">
                                            <h3>
                                                انطلق نحو مستقبل مشرق مع
                                                <p style={{ color: '#F89C42', fontSize: 60, padding: 10, margin: 10 }}>مهارة</p>
                                                شريكك في رحلتك المهنية
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-lg-6  order-1 order-lg-2 d-flex justify-content-center align-items-center mb-3">
                                        <div className="mask">
                                            <img src={require('../style/img/home/mr33.jpg')} alt="" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>

<div
    class="container"
>
    <div
        class="row justify-content-center align-items-center g-2"
    >
           <div class="col-xl-11 px-1 px-lg-4">
                <h5>بالتعاون مع  المديرية العامة للعلاقات والخدمات الاجتماعية نوفر لك الأدوات والفرص اللازمة فتح أبواب النجاح لتمكين مستقبلك المهني.</h5>
                <p>اكتسب المهارات التي تحتاجها، واحصل على الفرص التي تستحقها.</p>
                <h6>ما نقدمه لكم : </h6>
                <ul className='ul-list'>
                    <li><strong>تدريب متخصص</strong> : دورات تدريبية متقدمة في مختلف المجالات لرفع كفاءتك. </li>
                    <li><strong>فرص عمل حقيقية </strong> : اطلع على الوظائف المناسبة وتقدم للفرص المثالية لك. </li>
                    <li><strong>إرشاد مهني</strong>: فرصة للحصول على توجيهات مهنية من خبراء لمساعدتك في بناء مسارك المهني. </li>
                </ul>
                <h6>ميزات خدماتنا : </h6>
                <ul className='ul-list'>
                    <li><strong>تخصيص التدريب</strong> : نوفر دورات تعليمية تتناسب مع جدولك الزمني وتفضيلاتك الشخصية، سواء كان ذلك حضورياً أو عبر الإنترنت. </li>
                    <li><strong>شبكة واسعة</strong> : نعمل بالتعاون مع مجموعة كبيرة من الشركات والمؤسسات، مما يزيد من فرصك في العثور على الوظيفة المثالية. </li>
                    <li><strong>التوجيه المهني</strong>: نقدم لك المشورة المهنية والدعم اللازم لتحقيق أهدافك المهنية. </li>
                </ul>
            </div>
            <div class="col-12 my-2 d-flex justify-content-center align-items-center">
                <img src={require('../style/img/home/soon.png')} alt="" className="img-fluid" width={300} />
                {/* <h1 style={{ color: '#F89C42', textAlign: 'center', fontSize: 75 }} className='heartbeat'>قريباً</h1> */}
            </div>
    </div>
    
</div>

         
        </div>
        
   
    
  )
}
