import { Image } from 'antd'
import React from 'react'

export default function About() {
    React.useEffect(() => {
        const effect = () => {
            const ethics = document.getElementById('ethics-link');
            const missions = document.getElementById('missions-link');
            const skill = document.getElementById('skill-link');
            const scholarships = document.getElementById('scholarships-link');
            skill.classList.add('d-none')
            scholarships.classList.add('d-none')
            ethics.classList.add('d-none')
            missions.classList.add('d-none')
        }
        effect()
    },[])
    return (
        <div>
            <section id="about" className="hero d-flex align-items-center">

                <div className="container">
                    <div className="row   justify-content-center align-items-center">
                        <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
                            <h1 style={{ color: '#F89C42' }}>من نحن</h1>
                            <h2 style={{ fontSize: 20, textAlign: 'justify', lineHeight: 2 }}>مرحباً بكم في الموقع الرسمي لفريق أبناء المهندس والذي هو فريق طلابي متميز يجسد القيم الإنسانية والعلمية بأفضل صورها، حيث يسعى لتحقيق أهدافه من خلال تعزيز التفاعل الاجتماعي والتحصيل العلمي ، في فريق أبناء المهندس نهدف إلى تحقيق الثأر لدماء القادة، وذلك من خلال تمثيل مبادئ وقيم الشهيد شهيد الامة الباسل ابي مهدي المهندس رضوان الله عليه كما نسعى الى توفير بيئة تعليمية وتثقيفية محفزة وتنظيم فعاليات ثقافية وعلمية واجتماعية تعكس مهمتنا وقيمنا التي نؤمن بها بهدف تحقيق الريادة العلمية والثقافية في الجامعات والمعاهد العراقية</h2>
                        </div>
                        <div
                            className="col-lg-6 order-1 order-lg-2 d-flex align-items-center justify-content-center hero-img position-relative">
                            <img
                                src={require('../style/img/home/logo.png')}
                                className="img-fluid"
                                width={250}
                                alt=""
                            />
                        </div>
                    </div>
                </div>
            </section>
            <main id="royatna">
                <section id="target" className="testimonials">
                    <div className="container position-relative">
                        <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="testimonial-item">
                                        <img src={require('../style/img/home/logo_w.png')} className="testimonial-img" alt="" />
                                        <p className='mt-4'>
                                            <span>أبناء المهندس نحو الريادة والصدارة والتفوق بالمجال العلمي والعملي للطلبة</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="swiper-pagination"></div>
                        </div>
                    </div>
                </section>
                <section id="seeing" className="counts">
                    <div className="container">
                        <div className="text-center title">
                            <h3 style={{ fontSize: 45, color: '#F89C42' }}>رؤيتنا</h3>
                            <h4>نموذج للشاب الجامعي و للفرق الطلابية الفاعلة و الهادفة التي يحلم اي طالب ان يكون جزء منها</h4>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    )
}
