import React from 'react'
import { Progress, Result } from 'antd';
import { Modal, Button } from 'react-bootstrap';
export function AddPop(props) {
    return (
        <>
            <Modal
                size="md"
                centered={true}
                show={props.showOne}
                onHide={props.handleCloseOne}
                style={{ zIndex: 5000 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title> رفع البيانات</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <div>
                            <i className='bs bi-info-circle text-success' style={{ fontSize: 70 }}></i>
                        </div>
                        <div className='title-modal'>
                            هل انت متأكد من رفع البيانات
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.handleCloseOne}>
                        ألغاء
                    </Button>
                    <Button variant="" className='survey-btn' onClick={props.handleshow}>
                        متأكد
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                size="md"
                centered={true}
                show={props.showTwo}
                onHide={props.handleCloseTwo}
                animation={true}
                style={{ zIndex: 5000 }}

            >
                <Modal.Header>
                    <Modal.Title >جاري رفع البيانات</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column justify-content-center align-items-center '>
                        <Progress
                            type="circle"
                            strokeColor={{
                                '0%': '#108ee9',
                                '100%': '#87d068',
                            }}
                            percent={props.post}
                            format={() => <div className='d-flex justify-content-center align-items-center '>  <i className='fas fa-upload text-success' style={{ fontSize: 50 }}></i></div>}
                        />
                        <p>{props.post}%</p>
                        <p>لا تقم بعمل اعادة تحميل للصفحة انتظر نهاية العداد</p>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export function ErrorPop(props) {
    return (
        <>
            <Modal
                size="md"
                centered={true}
                show={props.showError}
                onHide={props.handleCloseError}
                style={{ zIndex: 5000 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>خطأ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Result
                        status="error"
                        title="حدث خطأ"
                        subTitle={props.errorMessage}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}
export function OKPop(props) {
    return (
        <>
            <Modal
                size="md"
                centered={true}
                show={props.showError}
                onHide={props.handleCloseError}
                style={{ zIndex: 5000 }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>رفع الاستمارة</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <Result
                            status="success"
                            title="تم رفع الاستمارة بنجاح"
                        />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

