import React from 'react'

export default function Loading() {
    return (
        <div className='loading'>
            <div className='loading-image'>
                <img src={require('../style/img/home/logo2.png')} width={300} />
            </div>
        </div>
    )
}
