import { Upload } from 'antd'
import React, { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons';
import axios from 'axios';

export default function Uploads({ fileSend, files, fileList, setFileList }) {

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    const customRequest = async ({ file, onSuccess }) => {
        const formData = new FormData();
        formData.append('file', file);
        const option = {
            headers: {
                "Content-Type": "multipart/form-data"
            },
        }
        console.log(files.length)
        if (files.length > 5) {
            return null
        
        } else {
            await axios.post('/archive/api/v1/files/', formData, option)
                .then(res => {
                    const id = res.data.id
                    fileSend(id)
                    onSuccess();
                })
          
        }
     
    };
    const propsfile = {
        listType: "picture-card",
        customRequest: customRequest,
        onChange: onChange,

    }
    const file_list = files.map(element => (
        {
            uid: `${element.id}`,
            name: element.file,
            status: 'done',
            url: element.file,
        }
    ));
    return (
        <Upload
            {...propsfile}
            defaultFileList={file_list}
            fileList={fileList}
            maxCount={5}
            onRemove={(file) => console.log(file)}
            accept='.png,.jpg,.jpeg,.pdf,.docx,.doc,.xlsx,.csv,.JPG'
            beforeUpload={() => fileList.length > 5 ? false : true}
        >
            <button
                style={{
                    border: 0,
                    background: 'none',
                }}
                type="button"
            >
                <PlusOutlined />
                <div
                    style={{
                        marginTop: 8,
                        fontFamily: 'RB-b'
                    }}
                >
                    رفع مرفق
                </div>
            </button>
        </Upload>
    )
}
